/*--------------------------------------------------------------
Custom
--------------------------------------------------------------*/
body {


  .jetpack-social-navigation-svg{
    .menu-social-container{
      #menu-social{
        svg.icon {
          color: inherit;
          fill: currentColor;
          height: 2.5em;
          vertical-align: middle;
          width: 2.5em;
        }
      }
    }
  }

  .page-jumbotron {
    .jumbo-title{
      color: #ffffff;
      font-size: 84px;
      letter-spacing: -.05em;
      line-height: .9;
      text-shadow: 2px 3px #0069b4;
      //background-color: rgba(0,169,239,0.5);
      padding: 15px;
      @media screen and (max-width: 767px){
        font-size: 44px;
      }
    }
    .section-subtitle {
        color: #fff;
        //background-color: rgba(0,169,239,0.5);
        padding: 15px;
        text-shadow: 2px 3px #0069b4;
    }
  }

  div.site-brand p.site-title a {
    font-size: 26px;
  }

  #masthead .navigation-main > div > ul a {
    padding-left: 10px;
    padding-right: 10px;
  }

  .item-info p.item-title{
    text-shadow: 2px 3px #0069b4;
  }


}
